<template>
  <div id="vue-core-video-player-box" class="example-player bg_light">
    <!-- <h1 class="white--text">URL: {{ activeVideo.downloadVideoUrl }}</h1> -->

    <!-- Show countdown if user is auth and video is scheduled for the future -->
    <div v-if="user && activeVideo && futureVideo" fluid>
      <v-row>
        <v-col cols="12">
          <v-row align="center" justify="center" style="height: 450px">
            <div>
              <h2 class="font-weight-bold headline pb-2">
                Prossimamente su questi schermi
              </h2>
              <p class="font-weight-light body-2 pb-5">Rimani connesso!</p>
              <!-- <countdown :time="2 * 24 * 60 * 60 * 1000">
                <template
                  slot-scope="props"
                >Time Remaining：{{ props.days }} days, {{ props.hours }} hours, {{ props.minutes }} minutes, {{ props.seconds }} seconds.</template>
              </countdown>-->
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!-- Show signup CTA if user is not auth and video is scheduled for the future -->
    <div v-if="!user && activeVideo && futureVideo" fluid>
      <v-row>
        <v-col cols="12">
          <v-row align="center" justify="center" style="height: 450px">
            <div>
              <h2 class="font-weight-bold headline pb-2">
                Video riservato agli utenti autenticati
              </h2>
              <p class="font-weight-light body-2 pb-5">
                Crea un account per poter accedere ai video
              </p>
              <v-btn color="primary" to="/signup" big>CREA ACCOUNT</v-btn>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!-- :src="`${activeVideo.downloadVideoUrl}#t=${timeToPlay}`" -->
    <!-- Show video player if url is visible and video can be played  -->
    <!-- :cover="activeVideo.downloadVideoCoverUrl" -->
    <!-- :logo="require('@/assets/techsoup_logo_orangewhite_icon.png')" -->
    <!-- :title="activeVideo.title" -->

    <!-- <h1>{{ autoplaySetting }}</h1> -->

    <vue-core-video-player
      v-if="activeVideo && activeVideo.downloadVideoUrl && !futureVideo"
      :src="activeVideo.downloadVideoUrl"
      :key="activeVideo.downloadVideoUrl"
      :autoplay="autoplaySetting"
      @play="playFunc"
      @pause="pauseFunc"
      @ended="endedFunc"
      style="border: 1px solid rgb(255, 255, 255, 0.5)"
    ></vue-core-video-player>

    <!-- Show upgrade banner if user is not paying -->
    <div
      v-if="
        user && activeVideo && !activeVideo.downloadVideoUrl && !futureVideo
      "
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-row align="center" justify="center" style="height: 450px">
            <div>
              <h2 class="font-weight-bold headline pb-2">
                Video riservato agli utenti abbonati
              </h2>
              <p class="font-weight-light body-2 pb-5">
                Sottoscrivi a Techsoup Together per sbloccarlo
              </p>
              <!-- <v-btn color="primary" big>INIZIA ORA</v-btn> -->
              <UpgradeButton big />
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <!-- Show signup CTA if user is not auth -->
    <div
      v-if="
        !user && activeVideo && !activeVideo.downloadVideoUrl && !futureVideo
      "
      fluid
    >
      <v-row>
        <v-col cols="12">
          <v-row align="center" justify="center" style="height: 450px">
            <div>
              <h2 class="font-weight-bold headline pb-2">
                Video riservato agli utenti autenticati
              </h2>
              <p class="font-weight-light body-2 pb-5">
                Crea un account per poter accedere ai video
              </p>
              <v-btn color="primary" to="/signup" big>CREA ACCOUNT</v-btn>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import UpgradeButton from "@/components/UpgradeButton.vue";

export default {
  name: "VideoPlayer",

  data() {
    return {
      // timeToPlay: 3,
      // videoSource: [
      //   {
      //     src: 'https://player.vimeo.com/external/476588033.sd.mp4?s=b7a5c59f4d88e72b0dba2f6c785e0ec5aebb6162&profile_id=164',
      //     resolution: '360p',
      //   }, {
      //     src: 'https://player.vimeo.com/external/476588033.sd.mp4?s=b7a5c59f4d88e72b0dba2f6c785e0ec5aebb6162&profile_id=165',
      //     resolution: '540p',
      //   }, {
      //     src: 'https://player.vimeo.com/external/476588033.hd.mp4?s=02eb317564ca3ca53fc937f91fe3a72de49efce3&profile_id=174',
      //     resolution: '720p',
      //   }, {
      //     src: 'https://media.vued.vanthink.cn/y2mate.com%20-%20sparkle_your_name_amv_K_7To_y9IAM_1080p.mp4',
      //     resolution: '1080p'
      //   }
      // ]
    };
  },

  components: {
    UpgradeButton,
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
    futureVideo() {
      let publishdate = new Date(this.activeVideo.datetime);
      let today = Date.now();
      let difference = today - publishdate;
      let a = false;
      if (difference < 0) {
        a = true;
      }
      return a;
    },
  },

  props: {
    activeVideo: {
      default: () => ({}),
    },
    timeToPlay: {
      default: () => ({}),
    },
    autoplaySetting: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    playFunc() {
      // playFunc(video) {
      // console.log("video has played!");
      // console.log(video);
      // console.log(`current time is: ${video.target.currentTime}`);

      // GA4 event: play video
      this.$gtag.query("event", "video_play", {
        video_id: this.activeVideo.id,
        video_ispaid: this.activeVideo.paid_only,
        video_istrailer: this.activeVideo.isTrailer,
        speaker_id: this.activeVideo.main_speakerId,
        speaker_slug: this.activeVideo.main_speaker,
      });

      this.autoplaySetting = true;
    },
    pauseFunc() {
      // console.log("video has paused!");
      // console.log(video);
      // console.log(`current time is: ${video.target.currentTime}`);

      // GA4 event: pause video
      this.$gtag.query("event", "video_pause", {
        video_id: this.activeVideo.id,
        video_ispaid: this.activeVideo.paid_only,
        video_istrailer: this.activeVideo.isTrailer,
        speaker_id: this.activeVideo.main_speakerId,
        speaker_slug: this.activeVideo.main_speaker,
      });
    },
    endedFunc() {
      // console.log("video has ended. maybe ask rating?");

      // GA4 event: ended video
      this.$gtag.query("event", "video_ended", {
        video_id: this.activeVideo.id,
        video_ispaid: this.activeVideo.paid_only,
        video_istrailer: this.activeVideo.isTrailer,
        speaker_id: this.activeVideo.main_speakerId,
        speaker_slug: this.activeVideo.main_speaker,
      });
    },
  },
};
</script>

<style scoped>
.example-player {
  height: 450px;
}
</style>